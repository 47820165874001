import React from 'react';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import ManageTasks from './ManageTasks';
import withTaskModuleWrapper from '../../hocs/withTaskModuleWrapper';
import { useSettingsSelector } from '../../selectors/useSettings';
import { getAppliedFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';

const included = [
  'jsonFiltersWithDefault',
  'taskModuleJsonFilters',
  'taskModule',
  'isAllFiltersLoading',
  'userSettings',
  'taskAssignee',
  'preCannedFilters',
  'dashboardFiltersEquipment,',
];

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(pick(nextProps, included)) === JSON.stringify(pick(prevProps, included));
const ManageTasksContainer = React.memo(ManageTasks, arePropsEqual);

const mapStateToProps = (state) => ({
  userResources: state.user.resources,
  userSettings: useSettingsSelector(state.user),
  jsonFiltersWithDefault: getAppliedFiltersWithDefault(state),
  taskStatuses: state.taskStatuses,
  taskModule: state.taskModule,
  currentFilterField: state.appliedFilters.currentFilterField,
  taskAssignee: state.dashboardFilters.taskAssignee,
  isAllFiltersLoading: loadingAllFiltersSelector(state),
  filterOpen: state.dashboardFilters.filterOpen,
  dashboardFiltersEquipment: state.dashboardFilters.equipment,
});

export default withTaskModuleWrapper(connect(mapStateToProps)(ManageTasksContainer));
