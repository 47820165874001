import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { process } from '@progress/kendo-data-query';
import { GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import EquipmentMenuPopup from './EquipmentMenuPopup';
import BaseGrid from '../common/Grid/BaseGrid';
import IconButton from '../common/IconButton';
import { getDatasetQueryStringParams } from '../widgets/widgetUtils';
import {
  getEquipmentModule,
  updateEquipmentModuleGridConfig,
  updateEquipmentModuleConfigColumns,
  updateEquipmentModuleConfig,
} from '../../actions/equipmentModuleActions';
import { hideModal, showModal } from '../../actions/modalActions';
import { CultureContext } from '../intl';
import equipmentColumns from './equipmentColumns';
import equipmentGridConfig from './equipmentGridConfig';
import { addToList, getPercentage, getTableWidth, parseQueryString, removeFromList, searchForMatchKeyword, getGridColumnWidthInPx } from '../../utils';
import { makeAsync } from '../common/Grid/utils';
import { createCellRenderer, exportCsv, formatExportExcelData } from './utils';
import useResizeObserver from '../../hooks/useResizeObserver';
import FilterContext from '../filters/FilterContext';
import HeaderCell from '../common/HeaderCell';
import ColumnSelectorPopup from '../admin/widgets/ColumnSelectorPopup';
import {userResources} from '../../enums/resources';
import configurationNotes from '../diagnostics/diagnosticModal/ConfigurationNotes';
import diagnosticsColumns from '../diagnostics/diagnosticsColumns';

const tableSelector = '.equipment-grid .k-widget.k-grid';

const ManageEquipment = (props) => {
  const {
    equipmentModule,
    userSettings,
    currentFilterField,
    jsonFiltersWithDefault,
    equipmentModuleJsonFilters,
    filterOverrides,
    dispatch,
    isAllFiltersLoading,
    filterOpen,
    search: locationSearch,
    resources,
    dashboardFiltersEquipment,
  } = props;

  const {
    jsonLayout,
  } = equipmentModule.config;
  const hasOnboardingReadPermission = resources.includes(userResources.Onboarding);
  const searchObject = parseQueryString(locationSearch);
  const queryGrid = searchObject.topBy ? { sort: [{ field: 'dateModified', dir: 'desc' }] } : null;

  const { culture } = useContext(CultureContext);
  const { setApplyFilters, setSaveFilters } = useContext(FilterContext);
  const anchorMenuRef = useRef();
  const menuWrapperRef = useRef();
  const columnSelectorPopupWrapperRef = useRef();
  const blurTimeoutRef = useRef();
  const exportExcelRef = useRef();

  const [popupAction, setPopupAction] = useState('btnExport');
  const [showMenu, setShowMenu] = useState(false);
  const [showColumnSelectorPopup, setShowColumnSelectorPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const [gridState, setGridState] = useState(() => createDataState(queryGrid || jsonLayout.grid || equipmentGridConfig, []));
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [equipmentList, setEquipmentList] = useState([]);
  const [search, setSearch] = useState('');
  const [appliedSearch, setAppliedSearch] = useState('');

  const [gridWidth, setGridWidth] = useState(768);
  const organizations = jsonFiltersWithDefault.find((e) => e.key === 'client');
  const isMultipleOrganization = organizations.value.length > 1;

  useResizeObserver(document.querySelector('.main-container'), (result) => {
    if (result) setGridWidth((prevWidth) => getTableWidth(prevWidth, tableSelector));
  });

  const hasChecked = Boolean(selectedIds.length);

  const selectedEquipment = useMemo(
    () => equipmentList.filter((item) => selectedIds.includes(item.eid)),
    [selectedIds, equipmentList],
  );

  const columns = useMemo(
    () => {
      const moduleColumns = Array.isArray(jsonLayout.columns) && jsonLayout.columns.length
        ? jsonLayout.columns
        : equipmentColumns;
      return hasOnboardingReadPermission
        ? moduleColumns
        : moduleColumns.filter((column) => column.field !== configurationNotes);
    },
    [jsonLayout.columns, hasOnboardingReadPermission]);

  const selectorColumns = useMemo(
    () => hasOnboardingReadPermission
      ? equipmentColumns
      : equipmentColumns.filter((column) => column.field !== 'configurationNotes'),
    [diagnosticsColumns, hasOnboardingReadPermission],
  );

  const allData = useMemo(
    () => {
      const config = { ...gridState.dataState, skip: 0, take: undefined };
      const list = createDataState(config, equipmentList);
      return list.result.data;
    },
    [gridState, equipmentList],
  );

  const visibleColumns = useMemo(
    () => columns.filter((column) => column.show),
    [columns],
  );

  const exportColumns = useMemo(
    () => {
      const noWidthColumns = visibleColumns.map((column) => omit(column, ['width']));
      return noWidthColumns;
    },
    [visibleColumns],
  );

  const savedComputedFilters = useMemo(() => {
    let newFilters =  [];

    equipmentModuleJsonFilters.forEach((eachFilter) => {
      const found = jsonFiltersWithDefault.find((e) => e.key === eachFilter.key);
      if (Array.isArray(eachFilter.value) && eachFilter.value.length) {
        newFilters.push(eachFilter);
      } else if (found) {
        newFilters.push(found);
      } else {
        newFilters.push(eachFilter);
      }
    });

    return newFilters;
  }, [jsonFiltersWithDefault, equipmentModuleJsonFilters]);

  const cellRender = useCallback(
    (column) => column.render
      ? createCellRenderer(column, { renderType: 'normal', culture, onRowClick: handleRowClick })
      : column.cell,
    [culture],
  );

  const stringifiedGridStateData = useMemo(
    () => {
      const withoutSelectedProperty = gridState.result.data.map((e) => omit(e, 'selected'));
      return JSON.stringify(withoutSelectedProperty);
    },
    [gridState.result.data, gridState.dataState.skip],
  );

  const renderedColumns = useMemo(
    () => visibleColumns.map((column) => (
      <GridColumn
        key={column.field}
        field={column.field}
        title={column.title}
        filter={column.filter}
        sortable={column.sortable}
        reorderable={column.reorderable !== false}
        orderIndex={column.orderIndex}
        width={getGridColumnWidthInPx(column, gridWidth)}
        headerClassName={column.headerClassName}
        className={column.className}
        render={column.render}
        cell={cellRender(column)}
        headerCell={HeaderCell}
      />
    )),
    [visibleColumns, cellRender, gridWidth, stringifiedGridStateData],
  );

  const handleRefresh = useCallback(({ dashboardFilters } = {}) => {
    const jsonFiltersToRefresh = equipmentModule.config.jsonFilters
      .map((e) => ({ key: e.key, value: e.value }))
      .filter((e) => Array.isArray(e.value) ? e.value.length : true);
    fetchEquipments(jsonFiltersToRefresh, dashboardFilters);
    setSelectedIds([]);
    setSearch('');
    setAppliedSearch('');
  }, [equipmentModule.config, jsonFiltersWithDefault]);

  const handleSave = useCallback(() => {
    const jsonFiltersToSave = equipmentModule.config.jsonFilters.map((e) => ({ key: e.key, value: e.value }));
    dispatchSave(jsonFiltersToSave);
  }, [equipmentModule.config]);

  const debouncedResize = useCallback(
    debounce((evt) => handleColumnResize(evt), 350),
    [columns, visibleColumns],
  );

  useEffect(() => {
    setApplyFilters(handleRefresh);
    setSaveFilters(handleSave);
  }, [handleRefresh, handleSave]);

  // load equipments on mount
  useEffect(() => {
    if (!loading && !isAllFiltersLoading && !filterOpen) {
      fetchEquipments(savedComputedFilters);
    }
  }, [queryString, currentFilterField, isAllFiltersLoading, savedComputedFilters, jsonFiltersWithDefault]);

  // update equipmentList on equipmentModule.list, appliedSearch change
  useEffect(() => {
    setEquipmentList(getEquipmentListWithSearch(equipmentModule.list));
  }, [equipmentModule.list, appliedSearch]);

  // update grid on equipmentList change
  useEffect(() => {
    setGridState(createDataState({ ...gridState.dataState, skip: 0 }, equipmentList));
  }, [equipmentList]);

  // update redux on grid change
  useEffect(() => {
    dispatch(updateEquipmentModuleGridConfig(gridState.dataState));
  }, [gridState.dataState]);

  // update grid on selectedIds change
  useEffect(() => {
    setGridState(createDataState(gridState.dataState, equipmentList));
  }, [selectedIds]);

  // update isAllSelected on selectedIds, equipmentList change
  useEffect(() => {
    if (!equipmentList.length || !selectedIds.length) {
      setIsAllSelected(false);
    } else {
      const diffList = equipmentList.filter((item) => !selectedIds.includes(item.eid));
      setIsAllSelected(!diffList.length);
    }
  }, [selectedIds, equipmentList]);

  function createDataState(dataState, equipmentRecords) {
    const list = equipmentRecords.map((item) => ({
      ...item,
      selected: selectedIds.some((s) => s === item.eid),
    }));
    const result = process(list, dataState);

    return {
      dataState,
      result,
    };
  }

  function fetchEquipments(jsonFilters, dashboardFilters) {
    const availableEquipment = dashboardFilters?.equipment || dashboardFiltersEquipment;
    const availableListValues = {
      taskStatuses: [],
      equipment: availableEquipment,
    };

    const mergedFilters = [...jsonFilters];
    const hasClientFilter = jsonFilters.some((s) => s.key === 'client');
    const clientFilter = jsonFiltersWithDefault.find((e) => e.key === 'client');
    if (!hasClientFilter && clientFilter) {
      mergedFilters.push(clientFilter);
    }

    const hasEquipmentFilter = jsonFilters.some((s) => s.key === 'building');
    const buildingFilter = jsonFiltersWithDefault.find((e) => e.key === 'building');
    if (!hasEquipmentFilter && buildingFilter) {
      mergedFilters.push(buildingFilter);
    }

    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      userSettings,
      mergedFilters,
      availableListValues,
      filterOverrides,
    });

    setQueryString(newQueryString);
    setQueryParams(newQueryParams);

    if (newQueryString) {
      setLoading(true);
      dispatch(
        getEquipmentModule({
          queryString: newQueryString,
          queryParams: newQueryParams,
        }),
      ).finally(() => {
        setLoading(false);
      });
    }
  }

  function dispatchSave(jsonFiltersToSave) {
    const jsonLayoutToSave = {
      ...equipmentModule.config.jsonLayout,
      columns: columns.map((e) => omit(e, ['widthInPx'])),
    };
    const params = {
      update: {
        jsonFilters: JSON.stringify(jsonFiltersToSave),
        jsonLayout: JSON.stringify(jsonLayoutToSave),
      },
    };
    dispatch(updateEquipmentModuleConfig(params)).then(() => {
      dispatch(hideModal());
      showSuccessToast('Configuration Saved.');
    });
  }

  function handleUpdateColumns(newColumns) {
    dispatch(updateEquipmentModuleConfigColumns(newColumns));
  }

  function handleColumnsSubmit(columnsState) {
    handleUpdateColumns(columnsState);
  }

  function handleColumnReorder(evt) {
    const newColumns = columns.map((oldCol) => {
      const foundColumn = evt.columns.find((newCol) => newCol.field === oldCol.field);
      if (foundColumn) {
        return {
          ...oldCol,
          orderIndex: foundColumn.orderIndex,
        };
      }
      return oldCol;
    });

    handleUpdateColumns(newColumns);
  }

  function handleColumnResize(evt) {
    if (evt.end) {
      const resizedColumn = visibleColumns.sort((a, b) => a.orderIndex - b.orderIndex).find((_, index) => evt.index === index + 1);
      const newColumns = columns.map((col) => {
        if (resizedColumn && resizedColumn.field === col.field) {
          return {
            ...col,
            width: getPercentage(gridWidth, evt.newWidth),
            widthInPx: evt.newWidth,
          };
        }
        return col;
      });
      handleUpdateColumns(newColumns);
    }
  }

  function expandChange(event) {
    // eslint-disable-next-line no-param-reassign
    event.dataItem[event.target.props.expandField] = event.value;
    setGridState({ ...gridState });
  }

  function dataStateChange(event) {
    setGridState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data, equipmentList),
    }));
  }

  function handleSelectionChange(event) {
    const { eid, selected } = event.dataItem;
    if (selected) {
      setSelectedIds((prevIds) => removeFromList(prevIds, eid));
    } else {
      setSelectedIds((prevIds) => addToList(prevIds, eid));
    }
  }

  function handleHeaderSelectionChange(event) {
    const { checked: selected } = event.syntheticEvent.target;
    if (selected) {
      const eids = equipmentList.map((item) => item.eid);
      setSelectedIds(eids);
    } else {
      setSelectedIds([]);
    }
  }

  function handleKeyPress(e) {
    if ((e.keyCode || e.which) === 13) {
      applySearch();
    }
  }

  function handleSearchChange(e) {
    setSearch(e.target.value);
  }

  function getEquipmentListWithSearch(list) {
    return list.filter((item) => {
      const {
        equipmentName, buildingName, equipmentClassName, equipmentTypeName,
      } = item;
      const fields = [
        equipmentName, buildingName, equipmentClassName, equipmentTypeName,
      ];
      const fullString = fields.filter((field) => field !== null).join('  ');
      return searchForMatchKeyword(fullString, appliedSearch);
    });
  }

  function applySearch() {
    setAppliedSearch(search);
  }

  function showSuccessToast(message) {
    toastr.success(message, message, {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  }

  function handleSelect(e) {
    setShowMenu(false);

    switch (e.item.text) {
      case 'Export CSV':
        handleExportCsv();
        break;
      case 'Export Excel':
        handleExportExcel();
        break;
      case 'Choose Columns':
        setShowColumnSelectorPopup(true);
        break;
      case 'Equipment Variables':
        handleShowBulkEditEquipmentVariables();
        break;
      case 'Equipment Details':
        handleShowEditEquipmentDetails();
        break;
      default:
        break;
    }
  }

  function blurTimeout() {
    showMenu && setShowMenu(false);
    showColumnSelectorPopup && setShowColumnSelectorPopup(false);
    blurTimeoutRef.current = undefined;
  }

  function handleMenuClick(evt) {
    anchorMenuRef.current = evt.target;
    setPopupAction(evt.target.name);
    setTimeout(() => setShowMenu((prevShow) => !prevShow));
  }

  function handlePopupOpen() {
    const wrapperEl = menuWrapperRef.current;
    wrapperEl && wrapperEl.focus();

    const wrapperEl2 = columnSelectorPopupWrapperRef.current;
    wrapperEl2 && wrapperEl2.focus();
  }

  function handleFocusHandler() {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = undefined;
  }

  function handleBlurHandler(evt) {
    const { relatedTarget } = evt;
    if (
      relatedTarget &&
      relatedTarget.getAttribute &&
      relatedTarget.getAttribute('id')
    ) {
      if (relatedTarget.getAttribute('id').includes('column-visibility')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-container')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-reset')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-submit')) return;
    }

    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(blurTimeout, 100);
  }

  function handleExportExcel() {
    if (exportExcelRef.current) {
      makeAsync(() => {
        const excelColumns = exportColumns.map((column) => ({
          ...column,
          width: getGridColumnWidthInPx(column, gridWidth),
          cell: column.render
            ? createCellRenderer(column, { renderType: 'excel', culture })
            : column.cell,
        }));
        const formattedAllData = formatExportExcelData(allData, excelColumns);
        exportExcelRef.current.save(formattedAllData, excelColumns);
      });
    }
  }

  function handleExportCsv() {
    makeAsync(() => {
      const csvColumns = exportColumns.map((column) => ({
        ...column,
        cell: column.render
          ? createCellRenderer(column, { renderType: 'csv', culture })
          : column.cell,
      }));
      exportCsv(`Equipment - ${Date.now()}`, csvColumns, allData);
    });
  }

  function closeModal() {
    handleRefresh();
    dispatch(hideModal());
  }

  function handleRowClick(event) {
    dispatch(
      showModal(
        'EQUIPMENT_MODAL',
        {
          culture,
          modalContent: 'full scrollable',
          cancelCallback: closeModal,
          dataItem: event.dataItem,
        },
      ),
    );
  }

  function handleShowBulkEditEquipmentVariables() {
    const equipmentList = selectedEquipment.map((e) => ({
      equipmentId: e.eid,
      equipmentClassId: e.equipmentClassId,
    }));
    dispatch(
      showModal(
        'BULK_EDIT_EQUIPMENT_VARIABLES',
        {
          modalContent: 'full scrollable',
          cancelCallback: closeModal,
          jsonFiltersWithDefault,
          equipmentList,
          isAllSelected: appliedSearch !== '' ? false : isAllSelected,
          appliedQueryParams: queryParams,
        },
      ),
    );
  }

  function handleShowEditEquipmentDetails() {
    const equipmentList = selectedEquipment.map((e) => ({
      equipmentId: e.eid,
      equipmentClassId: e.equipmentClassId,
    }));
    dispatch(
      showModal(
        'BULK_EDIT_EQUIPMENT_DETAILS',
        {
          modalContent: 'full scrollable',
          saveCallback: closeModal,
          cancelCallback: closeModal,
          jsonFiltersWithDefault,
          equipmentList,
          isAllSelected: appliedSearch !== '' ? false : isAllSelected,
          appliedQueryParams: queryParams,
        },
      ),
    );
  }

  return (
    <div data-testid="ManageEquipment" className="column content-spacing-container">
      <div className="level" style={{ marginBottom: '.5rem' }}>
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">Equipment</h1>
          </div>
        </div>

        <div className="level-right">
          <div className="level-item">
            <IconButton
              title="Save Configuration"
              className={`dashboard-icon-button button${equipmentModule.configSaving ? ' is-loading' : ''}`}
              iconName='flaticon-floppy-disk'
              onClick={handleSave}
            />
          </div>
        </div>
      </div>

      <EquipmentMenuPopup
        action={popupAction}
        show={showMenu}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
        selectedOrganizationId={equipmentList[0]?.unitId}
      />

      <ColumnSelectorPopup
        initialColumns={selectorColumns}
        columns={columns}
        show={showColumnSelectorPopup}
        menuRef={anchorMenuRef}
        menuWrapperRef={columnSelectorPopupWrapperRef}
        onPopupOpen={handlePopupOpen}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
        onColumnsSubmit={handleColumnsSubmit}
      />

      <BaseGrid
        wrapperClassName="equipment-grid"
        data={gridState.result}
        {...gridState.dataState}
        onDataStateChange={dataStateChange}
        onSelectionChange={handleSelectionChange}
        onHeaderSelectionChange={handleHeaderSelectionChange}
        expandField="expanded"
        selectedField="selected"
        onExpandChange={expandChange}
        sortable={{ allowUnsort: true }}
        onColumnReorder={handleColumnReorder}
        onColumnResize={debouncedResize}
        resizable
        reorderable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
        onRowClick={handleRowClick}
      >
        <GridToolbar>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <button
                  disabled={!hasChecked || isMultipleOrganization || !allData.length}
                  name="btnBulkAction"
                  className="button hidden"
                  title={hasChecked ? 'Bulk action' : 'No equipment are selected' }
                  onClick={handleMenuClick}
                >
                  Bulk Action
                </button>
                {isMultipleOrganization && <span className='m-2'><i>select a single organization to enable bulk action.</i></span>}
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <input
                  size={32}
                  type="text"
                  className="input"
                  placeholder="Enter text to search columns"
                  value={search}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="level-item">
                <button className="button" title="Search" onClick={applySearch}>
                  <span className="icon">
                    <i className="flaticon-search-interface-symbol" aria-hidden="true" />
                  </span>
                </button>
              </div>
              <div className="level-item">
                <button
                  name="btnExport"
                  className="button"
                  title="More"
                  ref={anchorMenuRef}
                  onClick={handleMenuClick}
                >
                  <span className="icon">
                    <i className="flaticon-menu" aria-hidden="true" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </GridToolbar>
        <GridNoRecords>
          {isAllFiltersLoading || loading
            ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
            : 'No records available'
          }
        </GridNoRecords>
        <GridColumn
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          headerSelectionValue={isAllSelected}
          width={34}
        />
        {renderedColumns}
      </BaseGrid>

      <ExcelExport
        ref={exportExcelRef}
        fileName={`Equipment - ${Date.now()}.xlsx`}
      />
    </div>
  );
};

ManageEquipment.propTypes = {
  resources: PropTypes.array.isRequired,
  search: PropTypes.string,
  jsonFiltersWithDefault: PropTypes.array,
  equipmentModuleJsonFilters: PropTypes.array,
  filterOverrides: PropTypes.string,
  userSettings: PropTypes.object.isRequired,
  equipmentModule: PropTypes.object.isRequired,
  currentFilterField: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  isAllFiltersLoading: PropTypes.bool,
  filterOpen: PropTypes.bool,
  dashboardFiltersEquipment: PropTypes.array,
};

export default ManageEquipment;
