/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Nav from './Nav';
import * as uiActions from '../../actions/uiActions';
import * as loginActions from '../../actions/loginActions';
import { userResources } from '../../enums/resources';
import navItems from '../../enums/navItems';

class NavManager extends React.Component {
  constructor(props) {
    super(props);
    const { user, reportTheme } = props;

    const staticNavData = [{
      name: navItems.home,
      url: '/home',
      icon: 'flaticon-home',
      isOpen: false,
      menu: [],
      resources: [],
    }, {
      name: navItems.dashboards,
      url: '/',
      icon: 'flaticon-dashboard',
      isOpen: false,
      menu: [],
      resources: [userResources.Dashboards],
    },
    {
      name: navItems.diagnostics,
      url: '/diagnostics',
      icon: 'flaticon-data-analysis',
      isOpen: false,
      menu: [],
      resources: [userResources.Diagnostics],
    },
    {
      name: navItems.tasks,
      url: '/tasks',
      icon: 'flaticon-checkmark',
      isOpen: false,
      resources: [userResources.Tasks],
      menu: [
        {
          id: 1,
          name: navItems.myTasks,
          url: '/tasks/my-tasks',
          resources: [userResources.Tasks],
        },
        {
          id: 2,
          name: navItems.ongoingTasks,
          url: '/tasks/on-going',
          resources: [userResources.Tasks],
        },
        {
          id: 3,
          name: navItems.completedTasks,
          url: '/tasks/completed',
          resources: [userResources.Tasks],
        },
        // {
        //   id: 4,
        //   name: 'Recent Activities',
        //   url: '/tasks/recent-activities',
        //   resources: [userResources.SystemAdministration],
        // },
      ],
    },
    {
      name: navItems.analysisBuilder,
      url: `https://${typeof reportTheme === 'number' && process.env.CLASSIC_URL ? process.env.CLASSIC_URL[reportTheme] : 'badurl'}/AnalysisBuilder?_e=${user.email}&_t=${user.timezoneOffset}&_l=${user.iDpLogout}`,
      absolute: true,
      icon: 'flaticon-graph',
      isOpen: false,
      menu: [],
      resources: [userResources.AnalysisBuilder],
    },
    {
      name: navItems.assets,
      icon: 'flaticon-business-and-trade',
      isOpen: false,
      resources: [userResources.Administration],
      menu: [
        {
          id: 1,
          name: navItems.buildings,
          url: '/assets/buildings',
          resources: [userResources.Buildings],
        },
        {
          id: 2,
          name: navItems.deployments,
          url: '/assets/deployments',
          resources: [userResources.SystemAdministration],
        },
        {
          id: 3,
          name: navItems.equipment,
          url: '/assets/equipment',
          resources: [userResources.Equipment],
        },
        {
          id: 4,
          name: navItems.points,
          url: '/assets/points',
          resources: [userResources.Points],
        },
      ],
    },
    {
      name: navItems.admin,
      isOpen: false,
      icon: 'flaticon-gear',
      menu: [
        {
          id: -5,
          name: navItems.widgets,
          url: '/admin/widgets',
          resources: [userResources.SystemAdministration],
        },
        {
          id: -6,
          name: navItems.dashboardTemplates,
          url: '/admin/manage-templates',
          resources: [userResources.DashboardTemplates, userResources.UnitDashboardTemplates],
        },
        {
          id: -7,
          name: navItems.emailTemplates,
          url: '/admin/email-templates',
          resources: [userResources.SystemAdministration],
        },
      ],
    },
    {
      name: navItems.diagnosticsPreview,
      url: '/diagnostics-preview',
      icon: 'flaticon-data-analysis',
      isOpen: false,
      menu: [],
      resources: [userResources.SystemAdministration],
    }].filter(Boolean);

    if (props.dashboards.length > 0) {
      staticNavData[0].menu = [...props.dashboards];
    }
    this.state = {
      navData: staticNavData,
    };
    this.toggleNavMenu = this.toggleNavMenu.bind(this);
    this.toggleLeftNav = this.toggleLeftNav.bind(this);
    this.toggleResize = this.toggleResize.bind(this);
  }

  toggleNavMenu(name, type) {
    var newNav = this.state.navData;
    //check if any other item is already opened if type is in then set to false if there are
    if (type === 'in' && this.state.navData.filter((item) => item.isOpen === true).length > 0) {
      newNav = this.state.navData.map((item) => item.isOpen = false);
    }
    // find the clicked item in the navData array and update the value of the isOpen property of the menu property of that item
    const clickedMenuIndex = this.state.navData.findIndex((m) => m.name === name);
    newNav = this.state.navData
      .slice(0, clickedMenuIndex)
      .concat(Object.assign({}, this.state.navData[clickedMenuIndex], { isOpen: type === 'in' }))
      .concat(this.state.navData.slice(clickedMenuIndex + 1));
    return this.setState({ navData: newNav });
  }

  toggleLeftNav(payload, isMobile) {
    if (isMobile) {
      this.props.actions.toggleLeftNav(payload);
    }
  }

  toggleResize(payload) {
    this.props.actions.toggleLeftNav(payload);
  }

  render() {
    const { isOpen, resources } = this.props;

    return (
      <Nav navData={this.state.navData} toggleMenu={this.toggleNavMenu} isOpen={isOpen} toggleLeftNav={this.toggleLeftNav} toggleResize={this.toggleResize} resources={resources} {...this.props} />
    );
  }
}

NavManager.propTypes = {
  dashboards: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  resources: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  reportTheme: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    dashboards: state.dashboards,
    resources: state.user.resources,
    isOpen: state.ui.leftnav.isOpen,
    user: state.user,
    reportTheme: state.whiteLabel.reportTheme,
  };
}

function MapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...loginActions, ...uiActions }, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, MapDispatchToProps)(NavManager));
