import React from 'react';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import ManageDiagnosticsPreview from './ManageDiagnosticsPreview';
import withDiagnosticsPreviewModuleWrapper from '../../hocs/withDiagnosticsPreviewModuleWrapper';
import { useSettingsSelector } from '../../selectors/useSettings';
import { getAppliedFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';

const included = [
  'jsonFiltersWithDefault',
  'diagnosticsPreviewModuleJsonFilters',
  'diagnosticsPreviewModule',
  'isAllFiltersLoading',
  'userSettings',
  'dashboardFiltersEquipment',
];

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(pick(nextProps, included)) === JSON.stringify(pick(prevProps, included));
const ManageDiagnosticsPreviewContainer = React.memo(ManageDiagnosticsPreview, arePropsEqual);

const mapStateToProps = (state) => ({
  userSettings: useSettingsSelector(state.user),
  jsonFiltersWithDefault: getAppliedFiltersWithDefault(state),
  taskStatuses: state.taskStatuses,
  diagnosticsPreviewModule: state.diagnosticsPreviewModule,
  currentFilterField: state.appliedFilters.currentFilterField,
  isAllFiltersLoading: loadingAllFiltersSelector(state),
  filterOpen: state.dashboardFilters.filterOpen,
  reportTheme: state.whiteLabel.reportTheme,
  user: state.user,
  modal: state.modal,
  dashboardFiltersEquipment: state.dashboardFilters.equipment,
});

export default withDiagnosticsPreviewModuleWrapper(connect(mapStateToProps)(ManageDiagnosticsPreviewContainer));
