import React from 'react';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import ManagePoints from './ManagePoints';
import withPointsModuleWrapper from '../../hocs/withPointsModuleWrapper';
import { useSettingsSelector } from '../../selectors/useSettings';
import { getAppliedFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';

const included = [
  'jsonFiltersWithDefault',
  'pointModuleJsonFilters',
  'pointModule',
  'isAllFiltersLoading',
  'userSettings',
  'dashboardFiltersEquipment',
];

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(pick(nextProps, included)) === JSON.stringify(pick(prevProps, included));
const ManagePointsContainer = React.memo(ManagePoints, arePropsEqual);

const mapStateToProps = (state) => ({
  userSettings: useSettingsSelector(state.user),
  jsonFiltersWithDefault: getAppliedFiltersWithDefault(state),
  pointModule: state.pointModule,
  currentFilterField: state.appliedFilters.currentFilterField,
  isAllFiltersLoading: loadingAllFiltersSelector(state),
  filterOpen: state.dashboardFilters.filterOpen,
  dashboardFiltersEquipment: state.dashboardFilters.equipment,
});

export default withPointsModuleWrapper(connect(mapStateToProps)(ManagePointsContainer));
