import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { GridColumn } from '@progress/kendo-react-grid';
import { getGridColumnWidthInPx } from '../../utils';
import BaseGrid from '../common/Grid/BaseGrid';
import GridDetailLoader from './GridDetailLoader';
import { createGridDetailDataState } from './utils';

const ManageDiagnosticsPreviewGridDetail = (props) => {
  const { dataItem, gridWidth, jsonLayout, visibleColumns, cellRender } = props;
  const listDetailData = useSelector((state) => state.diagnosticsPreviewModule.listDetail[dataItem.rowKey]);
  const gridState = useMemo(
    () => createGridDetailDataState({ sort: jsonLayout.grid.sort, take: undefined, skip: 0 }, listDetailData),
    [listDetailData],
  );

  const renderedColumns = useMemo(
    () => visibleColumns.map((column) => (
      <GridColumn
        key={column.field}
        field={column.field}
        title={column.title}
        filter={column.filter}
        orderIndex={column.orderIndex}
        width={getGridColumnWidthInPx(column, gridWidth - 32)}
        headerClassName={column.headerClassName}
        render={column.render}
        cell={cellRender(column, 'gridDetail')}
      />
    )),
    [visibleColumns, cellRender, gridWidth],
  );

  return (
    <>
      <GridDetailLoader />
      <BaseGrid
        data={gridState.result}
        {...gridState.dataState}
        selectedField="selected"
        scrollable="none"
        headerCellRender={() => null}
        resizable
      >
        <GridColumn
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          headerSelectionValue={false}
          width={34}
        />
        {renderedColumns}
      </BaseGrid>
    </>
  );
};

ManageDiagnosticsPreviewGridDetail.propTypes = {
  dataItem: PropTypes.object,
  gridWidth: PropTypes.number,
  jsonLayout: PropTypes.object,
  visibleColumns: PropTypes.array,
  appliedQueryParams: PropTypes.object,
  cellRender: PropTypes.func,
};

export default memo(ManageDiagnosticsPreviewGridDetail);
