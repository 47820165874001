/* eslint-disable react/prefer-stateless-function */
// This component handles the App template used on every page.
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import ReduxToastr from 'react-redux-toastr';
import MainContainer from './main/MainContainer';
import CSSVariableApplicator from './common/CSSVariableApplicator';
import ManageLogin from './login/ManageLogin';
import * as loginActions from '../actions/loginActions';
import UserIsAuthenticated from './login/UserIsAuthenticated';
import NavManager from './nav/NavManager';
import HeaderManager from './header/HeaderManager';
import Footer from './common/Footer';
import * as uiActions from '../actions/uiActions';
import * as dashboardActions from '../actions/dashboardActions';
import ModalsContainer from './modals/ModalsContainer';
import SessionManager from './SessionManager';
import PageLoading from './common/PageLoading';
import PageNotFound from './PageNotFound';
import { withLocalization } from './intl';
import cwfavicon from '../images/cwfavicon.ico';
import sefavicon from '../images/sefavicon.ico';
import invisible from '../images/invisible.ico';
import FilterManager from './filters/FilterManager';
import FilterProvider from './filters/FilterProvider';
const UserAuthenticatedMainContainer = UserIsAuthenticated(MainContainer);

const pathsExact = [
  '/', '/home', '/diagnostics', '/diagnostics-preview',
  '/admin/widgets', '/admin/manage-templates', '/admin/email-templates',
  '/assets/buildings', '/assets/equipment', '/assets/points',
];
const pathsContains = ['/tasks', '/admin/manage-templates/org/'];

class App extends React.PureComponent {
  applyFilters = React.createRef(null);

  componentDidMount() {
    this.setFavicon();
    this.setBodyClass();
  }

  componentDidUpdate(prevProps) {
    if (this.props.landingPage !== prevProps.landingPage && this.props.location.pathname === '/') {
      this.props.actions.replace(this.props.landingPage);
    }
    this.setFavicon();
  }

  setFavicon = () => {
    const { theme, isAuthenticated } = this.props;
    const favicon = document.getElementById('favicon');
    const isLoggedIn = isAuthenticated;

    if (isLoggedIn) {
      favicon.href = theme.iconImage;
      return;
    }

    switch (theme.reportTheme) {
      case 0:
        favicon.href = cwfavicon;
        break;
      case 1:
        favicon.href = sefavicon;
        break;
      default:
        favicon.href = invisible;
        break;
    }
  };

  setBodyClass() {
    const isIE = /*@cc_on!@*/!!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    if (isEdge) {
      document.body.classList.add('edge');
    }
  }

  showFilters() {
    const { location: { pathname } } = this.props;
    let show = false;

    show = pathsExact.includes(pathname.toLowerCase());
    if (!show) {
      show = pathsContains.some((s) => pathname.toLowerCase().includes(s));
    }
    if (!show) {
      const id = Number(pathname.replace('/', ''));
      show = id && typeof id === 'number';
    }
    if (!show) {
      const id = Number(pathname.replace('/admin/manage-templates/', ''));
      show = id && typeof id === 'number';
    }

    return show;
  }

  render() {
    const { loadingTheme, theme, isAuthenticated, authUserAppInitialized } = this.props;

    document.title = theme.productName ? theme.productName : ' ';

    return (
      <CSSVariableApplicator variables={theme.styles} loading={loadingTheme}>
        {(authUserAppInitialized || !isAuthenticated) ? (
          <div className="fullpage">
            <div className={'sticky-footer'}>
              <FilterProvider>
                <HeaderManager />
                <Switch>
                  <Route exact path="/not-found" component={PageNotFound} />
                  <Route>
                    <div className="columns main-content is-mobile">
                      {isAuthenticated && <NavManager />}
                      <div className={`column main-nav ${this.props.location.pathname !== '/login' ? 'main-nav-bg' : ''}`}>
                        {(isAuthenticated && this.showFilters() === true) && <FilterManager />}
                        <Switch>
                          <Route exact path="/login" component={ManageLogin} />
                          <Route path="/" component={UserAuthenticatedMainContainer} />
                        </Switch>
                      </div>
                    </div>
                  </Route>
                </Switch>
              </FilterProvider>
              <Footer />
            </div>
          </div>
        ) : (
          <PageLoading label={`Loading ${theme.prodName || ''}`} />
        )}
        <ReduxToastr
          timeOut={5000}
          newestOnTop={false}
          preventDuplicates
          position="bottom-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <ModalsContainer />
        <SessionManager />
      </CSSVariableApplicator>
    );
  }
}

App.propTypes = {
  loadingTheme: PropTypes.bool.isRequired,
  theme: PropTypes.object,
  location: PropTypes.object,
  actions: PropTypes.object.isRequired,
  landingPage: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  authUserAppInitialized: PropTypes.bool.isRequired,
};


// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  return {
    theme: state.whiteLabel,
    loadingTheme: state.ajaxCallsInProgress.theme,
    isAuthenticated: state.user.isAuthenticated,
    landingPage: state.user.landingPage,
    authUserAppInitialized: state.user.authUserAppInitialized,
  };
}

function mapDisptachToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...loginActions, replace, ...uiActions, ...dashboardActions }, dispatch),
  };
}

export default compose(
  withRouter,
  withLocalization,
  connect(mapStateToProps, mapDisptachToProps),
)(App);
