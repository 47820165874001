import diagnosticsGrid from '../components/diagnostics/diagnosticsGrid';
import diagnosticsPreviewGrid from '../components/diagnosticsPreview/diagnosticsPreviewGrid';
import buildingGridConfig from '../components/buildings/buildingGridConfig';
import equipmentGridConfig from '../components/equipment/equipmentGridConfig';
import pointGridConfig from '../components/points/pointGridConfig';
import { topFilterDefaultValues } from '../components/diagnostics/TopFilter';
import { filterFields } from '../enums/filters';
import { getCookieByName } from '../utils';

const logoutVal = getCookieByName('iDpLogout');

export default {
  deploymentGroups: [],
  editEquipmentFlags: [],
  equipmentDataset:[],
  assignedUsersEmailTemplate: {
    assignedUsers: [],
  },
  reprocessDiagnostics:[],
  emailTemplates: [],
  appliedFilters: {
    currentFilterField: filterFields.disabled,
    excludePageFilterIds: [],
  },
  disabled: {
    jsonFilters: [],
  },
  permissions: {
    dashboard: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    dashboardTemplates: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    orgDashboardTemplates: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    userOrgDashboardTemplates: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    building: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    equipment: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    equipmentVariables: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    buildingVariables: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    org: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    point: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    equipmentByUnitId: {},
    equipmentVariablesByUnitId: {},
    pointsByUnitId: {},
    scheduledAnalysesByUnitId: {},
  },
  taskModule: {
    list: [],
    assigneesAndReporters: null,
    assigneesAndReportersLoading: false,
    details: null,
    permissions: null,
    detailsLoading: false,
    detailsSaving: false,
    deleting: false,
    bulkDeleting: false,
    bulkEditing: false,
    configSaving: false,
    creating: false,
    lastCreated: null,
    config: {
      jsonLayout: {
        grid: {
          sort: [{ field: 'summary', dir: 'asc' }],
          take: 50,
          skip: 0,
        },
        columns: [],
      },
      jsonFilters: [],
      jsonFiltersFromAPI: [],
      jsonFiltersFromAPILoaded: false,
    },
    taskPermissionsByUnitId: {},
  },
  diagnosticsModule: {
    list: [],
    config: {
      jsonLayout: {
        topBy: topFilterDefaultValues.topBy,
        topValue: topFilterDefaultValues.topValue,
        grid: diagnosticsGrid,
        columns: [],
      },
      jsonFilters: [
        {
          'key': 'diagnosticDate',
          'value': {
            'value': 'last_7_days',
            'range': {},
          },
        },
        {
          'key': 'diagnosticAnalysisInterval',
          'value': 'Daily',
        },
      ],
      jsonFiltersFromAPI: [],
      jsonFiltersFromAPILoaded: false,
    },
    currentDiagnosticDetail: {},
    currentDiagnosticDetailLoaded: false,
    currentDiagnosticDetailLoading: false,
    equipmentProfile: {
      data: {},
      equipmentVariables: [],
      equipmentPoints: [],
      buildingVariables: [],
      associatedEquipment: [],
      associatedEquipmentVariables: [],
      associatedEquipmentPoints: [],
      equipmentVariablesValueLoading: false,
      buildingVariablesValueLoading: false,
    },
    bulkEditEquipmentDetails: {
      saving: false,
      lastUpdated: null,
    },
    equipmentVariablesList: [],
    equipmentVariablesListLoading: false,
    pointTypeViewList: [],
    pointTypeViewListLoading: false,
  },
  diagnosticsPreviewModule: {
    list: [],
    listDetail: {},
    listDetailLoading: false,
    config: {
      jsonLayout: {
        topBy: topFilterDefaultValues.topBy,
        topValue: topFilterDefaultValues.topValue,
        grid: diagnosticsPreviewGrid,
        columns: [],
      },
      jsonFilters: [
        {
          'key': 'diagnosticDate',
          'value': {
            'value': 'last_7_days',
            'range': {},
          },
        },
        {
          'key': 'diagnosticAnalysisInterval',
          'value': 'Daily',
        },
      ],
      jsonFiltersFromAPI: [],
      jsonFiltersFromAPILoaded: false,
    },
  },
  homeModule: {
    config: {
      jsonFilters: [
        {
          'key': 'diagnosticDate',
          'value': {
            'value': 'last_30_days',
            'range': {},
          },
        },
        {
          'key': 'diagnosticAnalysisInterval',
          'value': 'Daily',
        },
        {
          'key': 'taskModifiedDate',
          'value': {
            'value': 'last_90_days',
            'range': {},
          },
        },
      ],
      jsonFiltersFromAPI: [],
      jsonFiltersFromAPILoaded: false,
    },
    equipmentVariablesList: [],
    equipmentVariablesListLoading: false,
    buildingVariablesList: [],
    buildingVariablesListLoading: false,
  },
  buildingModule: {
    list: [],
    lastUpdated: null,
    configSaving: false,
    buildingSaving: false,
    config: {
      jsonLayout: {
        grid: buildingGridConfig,
        columns: [],
      },
      jsonFilters: [],
      jsonFiltersFromAPI: [],
      jsonFiltersFromAPILoaded: false,
    },
    buildingByIdPermissions: {},
  },
  equipmentModule: {
    list: [],
    configSaving: false,
    equipmentSaving: false,
    config: {
      jsonLayout: {
        grid: equipmentGridConfig,
        columns: [],
      },
      jsonFilters: [],
      jsonFiltersFromAPI: [],
      jsonFiltersFromAPILoaded: false,
    },
  },
  pointModule: {
    list: [],
    lastUpdated: null,
    configSaving: false,
    pointSaving: false,
    config: {
      jsonLayout: {
        grid: pointGridConfig,
        columns: [],
      },
      jsonFilters: [],
      jsonFiltersFromAPI: [],
      jsonFiltersFromAPILoaded: false,
    },
  },
  map: {
    clientId: '',
    token: '',
  },
  ajaxCallsInProgress: {
    buildingsFilterResources: false,
    theme: true,
    resources: false,
    renew: false,
    dashboard: false,
    dashboardSave: false,
    dashboardClone: false,
    dashboardLoad: false,
    equipmentNotes: false,
    configurationNotes: false,
    equipmentProfile: false,
    widgetsSave: false,
    userSave: false,
    contactSave: false,
    widgetsClone: false,
    dashboardTemplateAssign: false,
    dashboardTemplatePermissions: true,
    orgDashboardTemplate: false,
    globalDashboardTemplate: false,
  },
  linecharts: [],
  whiteLabel: { styles: {} },
  adminWidgets: {
    widgetTypes: [],
    widgetCategories: [],
    widgetDatasets: [],
    widgetAnalysisTasks: [],
    widgetsSummary: [],
    currentWidgetDetail: {},
  },
  buildings: [],
  buildingSettings: [],
  buildingClasses: [],
  buildingTypes: [],
  taskStatuses: [],
  analyses: [],
  unitBuildingGroups: [],
  buildingGroupBuildings: [],
  units: [],
  dashboards: [],
  crossFilter: {
    enabled: false,
    areEqual: false,
    current: {
      widgetId: null,
      filters: [],
    },
    previous: {
      widgetId: null,
      filters: [],
    },
  },
  drilldown: {
    loading: {},
  },
  currentDashboard: {
    enableDateIntervalChange: true,
    jsonWidgetSettings: {
      widgets: [],
    },
    jsonWidgetSettingsFromServer: {
      widgets: [],
    },
    templateJsonWidgetSettings: {
      widgets: [],
    },
    jsonFilters: [],
    jsonFiltersFromAPI: [],
  },
  currentWidget: {
    id: null,
    jsonFilters: [],
  },
  dashboardFilters: {
    filterOpen: false,
    dashboardFiltersLoaded: false,
    widgetFiltersLoaded: false,
    providerLoaded: false,
    clientLoaded: false,
    deploymentGroupLoaded: false,
    buildingGroupLoaded: false,
    buildingClassLoaded: false,
    buildingTypeLoaded: false,
    buildingLoaded: false,
    equipmentLoaded: false,
    provider: [],
    providerAll: [],
    client: [],
    clientAll: [],
    deploymentGroup: [],
    deploymentGroupAll: [],
    buildingGroup: [],
    buildingGroupAll: [],
    buildingClass: [],
    buildingClassAll: [],
    buildingType: [],
    buildingTypeAll: [],
    building: [],
    buildingAll: [],
    equipmentClass: [],
    equipmentClassAll: [],
    equipmentType: [],
    equipmentTypeAll: [],
    equipment: [],
    equipmentAll: [],
    taskAssignee: [],
    taskAssigneeAll: [],
    taskReporter: [],
    taskReporterAll: [],
    currency: [],
    currencyAll: [],
    analysis: [],
    analysisAll: [],
    equipmentAnalysisAll: [],
    dataSource: [],
    dataSourceAll: [],
    pointClass: [],
    pointClassAll: [],
    pointType: [],
    pointTypeAll: [],
    point: [],
    pointAll: [],
    pointAllParams: {},
    vPoint: [],
    vPointAll: [],
    availableData: {
      allOrgs: [],
      allProviders: [],
      allBuildingGroupsClassesTypesBuildings: [],
    },
  },
  loadingFilters: {
    equipmentClass: false,
    equipmentType: false,
    equipment: false,
    analysis: false,
    building: false,
    client: false,
  },
  calculatingFilters: {
    equipmentClass: true,
    equipmentType: true,
    equipment: true,
    pointClass: false,
    pointType: false,
    point: false,
    currency: true,
    taskAssignee: true,
    taskReporter: true,
    dataSource: true,
    analysis: true,
    building: false,
    client: false,
    provider: false,
  },
  equipmentLookup: {
    classes: [],
    types: [],
    equipments: [],
    equipmentConfigurationStatuses: [],
    classesLoaded: false,
    typesLoaded: false,
    equipmentsLoaded: false,
    equipmentConfigurationStatusesLoaded: false,
  },
  pointLookup: {
    classes: [],
    types: [],
    classesLoaded: false,
    typesLoaded: false,
  },
  lookup: {
    countries: [],
    states: [],
    timezones: [],
    cultures: [],
  },
  equipmentTypes: [],
  dashboardNames: [],
  dashboardTemplates: [],
  userOrgTemplates: {
    list: [],
    loaded: false,
  },
  user: {
    landingPage: '/',
    authenticating: false,
    authUserAppInitialized: false,
    sessionTimeout: 20,
    sessionInterval: null,
    email: '',
    lastApiCallTime: null,
    iDpLogout: (logoutVal === 'true') || false,
    iDpRedirectUrl: '',
    isAuthenticated: false,
    isIdpSelectable: false,
    orgName: '',
    resources: [],
    gfid: null,
    isSi: false,
    unitId: null,
    currencies: [],
    timezoneOffset: new Date().getTimezoneOffset() / -60,
    authorizedUsers: [],
    jsonFiltersInitialized: false,
    jsonFiltersChanged: false,
    jsonFilters: [
      {
        key: 'provider',
        value: [],
      },
      {
        key: 'client',
        value: [],
      },
    ],
  },
  modal: {
    visible: false,
    modalType: null,
    modalProps: {},
    modals: [],
  },
  ui: {
    leftnav: {
      isOpen: !(window.innerWidth < 769),
    },
    rightnav: {
      isOpen: false,
    },
  },
  dataset: {
    queryStrings: {},
    widgetsLoading: {},
    reloadWidgets: {},
  },
  orgDashboardTemplates: {
    list: [],
    organizations: [],
    assignedUsers: [],
    organizationsWithCreatePermission: [],
  },
  globalDashboardTemplates: {
    assignedUsers: [],
  },
};
