import { createSelector } from 'reselect';
import { filterFields } from '../enums/filters';

const getDashboardJsonFilters = (state) => state.currentDashboard.jsonFilters;
const getFilterField = (state, ownProps = {}) => ownProps.currentFilterField || state.appliedFilters.currentFilterField;
const getWidgetJsonFilters = (state) => state.currentWidget.jsonFilters;
const getTaskJsonFilters = (state) => state.taskModule.config.jsonFilters;
const getDiagnosticsJsonFilters = (state) => state.diagnosticsModule.config.jsonFilters;
const getDiagnosticsPreviewJsonFilters = (state) => state.diagnosticsPreviewModule.config.jsonFilters;
const getHomeJsonFilters = (state) => state.homeModule.config.jsonFilters;
const getBuildingJsonFilters = (state) => state.buildingModule.config.jsonFilters;
const getEquipmentJsonFilters = (state) => state.equipmentModule.config.jsonFilters;
const getPointJsonFilters = (state) => state.pointModule.config.jsonFilters;
const getDisabledJsonFilters = (state) => state.disabled.jsonFilters;

export const getAppliedDashboardFilters = createSelector(
  [
    getDashboardJsonFilters,
    getFilterField,
    getWidgetJsonFilters,
    getTaskJsonFilters,
    getDiagnosticsJsonFilters,
    getDiagnosticsPreviewJsonFilters,
    getHomeJsonFilters,
    getBuildingJsonFilters,
    getEquipmentJsonFilters,
    getPointJsonFilters,
    getDisabledJsonFilters,
  ],
  (
    dashboardFilters,
    filterField,
    widgetFilters,
    taskFilters,
    diagnosticsFilters,
    diagnosticsPreviewFilters,
    homeFilters,
    buildingFilters,
    equipmentFilters,
    pointFilters,
    disabledFilters,
  ) => {
    let jsonFilters;
    
    switch (filterField) {
      case filterFields.currentDashboard:
        jsonFilters = dashboardFilters;
        break;
      case filterFields.currentWidget:
        jsonFilters = widgetFilters;
        break;
      case filterFields.taskModule:
        jsonFilters = taskFilters;
        break;
      case filterFields.diagnosticsModule:
        jsonFilters = diagnosticsFilters;
        break;
      case filterFields.diagnosticsPreviewModule:
        jsonFilters = diagnosticsPreviewFilters;
        break;
      case filterFields.homeModule:
        jsonFilters = homeFilters;
        break;
      case filterFields.buildingModule:
        jsonFilters = buildingFilters;
        break;
      case filterFields.equipmentModule:
        jsonFilters = equipmentFilters;
        break;
      case filterFields.pointModule:
        jsonFilters = pointFilters;
        break;
      case filterFields.disabled:
        jsonFilters = disabledFilters;
        break;
      default:
        jsonFilters = dashboardFilters;
        break;
    }

    return jsonFilters;
  },
);
